.action-button-panel {
  margin: 10px 0px;
}

.action-button-panel .btn-group {
  margin: 5px 0px;
}

.button-panel {
  margin: 30px 0px;
  border-top: dashed 2px $laravel-border-color;
  width: 100%;
}

.button-panel-right {
  float: right;
  padding: 10px;
}

.button-panel-left {
  float:left;
  padding: 10px;
}

.inner-button-panel {
  border-top: dashed 1px $laravel-border-color;
  margin-top: 20px;
  padding: 10px;
  text-align: right;
}