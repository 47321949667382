.form-group {
  clear: both;
  padding-top: 10px;
}

.form-group label {
  padding: 7px 0px;
  text-align: left;
}

.form div[class^="col-"], div[class*=" col-"] {
  margin-top: 10px;
}